@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unlock&display=swap");

/* Gilroy */
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: local("Gilroy-Bold"),
    url(assets/fonts/Gilroy/Gilroy-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: local("Gilroy-Bold"),
    url(assets/fonts/Gilroy/Gilroy-BoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: local("Gilroy-Heavy"),
    url(assets/fonts/Gilroy/Gilroy-Heavy.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: local("Gilroy-Heavy"),
    url(assets/fonts/Gilroy/Gilroy-HeavyItalic.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: local("Gilroy-Extrabold"),
    url(assets/fonts/Gilroy/Gilroy-ExtraBold.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  src: local("Gilroy-Extrabold"),
    url(assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local("Gilroy-Semibold"),
    url(assets/fonts/Gilroy/Gilroy-SemiBold.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Gilroy-Semibold"),
    url(assets/fonts/Gilroy/Gilroy-SemiBoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"), local("Gilroy-Medium"),
    url(assets/fonts/Gilroy/Gilroy-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: local("Gilroy"), local("Gilroy-Medium"),
    url(assets/fonts/Gilroy/Gilroy-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Gilroy"), local("Gilroy-Regular"),
    url(assets/fonts/Gilroy/Gilroy-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("Gilroy"), local("Gilroy-Regular"),
    url(assets/fonts/Gilroy/Gilroy-RegularItalic.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy"), local("Gilroy-Light"),
    url(assets/fonts/Gilroy/Gilroy-Light.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: local("Gilroy"), local("Gilroy-Light"),
    url(assets/fonts/Gilroy/Gilroy-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: local("Gilroy"), local("Gilroy-UltraLight"),
    url(assets/fonts/Gilroy/Gilroy-UltraLight.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 200;
  src: local("Gilroy"), local("Gilroy-UltraLight"),
    url(assets/fonts/Gilroy/Gilroy-UltraLightItalic.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: local("Gilroy"), local("Gilroy-Thin"),
    url(assets/fonts/Gilroy/Gilroy-Thin.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 100;
  src: local("Gilroy"), local("Gilroy-Thin"),
    url(assets/fonts/Gilroy/Gilroy-ThinItalic.otf) format("opentype");
}

/* VarelaRound */
@font-face {
  font-family: "VarelaRound";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: local("VarelaRound"), local("VarelaRound-Regular"),
    url(assets/fonts/Varela_Round/VarelaRound-Regular.otf) format("opentype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url(assets/fonts/Helvetica/HelveticaNeueCyr-Roman.ttf) format("truetype");
}

.sizes-test-block {
  margin: 0;
  padding: 0;
  width: min-content;
  position: fixed;
  top: -150px;
  left: -150px;
  pointer-events: none;
  opacity: 0;
}

#root {
  position: relative;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mainFallBack {
  background: #301b52;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 15px;
}

body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

body > iframe {
  pointer-events: none;
}
