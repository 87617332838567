@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Unlock&display=swap);
/* Gilroy */
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: local("Gilroy-Bold"),
    url(/static/media/Gilroy-Bold.a64a9abe.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: local("Gilroy-Bold"),
    url(/static/media/Gilroy-BoldItalic.b6696a23.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: local("Gilroy-Heavy"),
    url(/static/media/Gilroy-Heavy.401eb832.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: local("Gilroy-Heavy"),
    url(/static/media/Gilroy-HeavyItalic.4b257d20.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: local("Gilroy-Extrabold"),
    url(/static/media/Gilroy-ExtraBold.f0feeaf3.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  src: local("Gilroy-Extrabold"),
    url(/static/media/Gilroy-ExtraBoldItalic.250db730.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local("Gilroy-Semibold"),
    url(/static/media/Gilroy-SemiBold.017ff10c.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Gilroy-Semibold"),
    url(/static/media/Gilroy-SemiBoldItalic.a1676eaf.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"), local("Gilroy-Medium"),
    url(/static/media/Gilroy-Medium.9f1092d0.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: local("Gilroy"), local("Gilroy-Medium"),
    url(/static/media/Gilroy-MediumItalic.06358c18.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Gilroy"), local("Gilroy-Regular"),
    url(/static/media/Gilroy-Regular.86bc2a5a.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("Gilroy"), local("Gilroy-Regular"),
    url(/static/media/Gilroy-RegularItalic.0a3ab01d.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy"), local("Gilroy-Light"),
    url(/static/media/Gilroy-Light.5ac18846.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: local("Gilroy"), local("Gilroy-Light"),
    url(/static/media/Gilroy-LightItalic.c03158a6.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: local("Gilroy"), local("Gilroy-UltraLight"),
    url(/static/media/Gilroy-UltraLight.f740ead4.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 200;
  src: local("Gilroy"), local("Gilroy-UltraLight"),
    url(/static/media/Gilroy-UltraLightItalic.133c3ad8.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: local("Gilroy"), local("Gilroy-Thin"),
    url(/static/media/Gilroy-Thin.b418ea10.otf) format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  font-style: italic;
  font-weight: 100;
  src: local("Gilroy"), local("Gilroy-Thin"),
    url(/static/media/Gilroy-ThinItalic.255e8336.otf) format("opentype");
}

/* VarelaRound */
@font-face {
  font-family: "VarelaRound";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: local("VarelaRound"), local("VarelaRound-Regular"),
    url(/static/media/VarelaRound-Regular.7d71d67d.otf) format("opentype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/HelveticaNeueCyr-Roman.0ca719c7.ttf) format("truetype");
}

.sizes-test-block {
  margin: 0;
  padding: 0;
  width: -webkit-min-content;
  width: min-content;
  position: fixed;
  top: -150px;
  left: -150px;
  pointer-events: none;
  opacity: 0;
}

#root {
  position: relative;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mainFallBack {
  background: #301b52;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 15px;
}

body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

body > iframe {
  pointer-events: none;
}

